/* eslint-disable */
import HttpService from '../HttpService';
export default class EuProtegidoService {
  constructor() {/* eslint-disable */
    this._httpService = new HttpService(`euprotegido`);
  }

  SendMessageToChatBot(data) {
    return this._httpService.post("/websocket_message/sendMessageToChatBot", data);
  }

}

