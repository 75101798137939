<template>
  <v-container class="bg-glass-effect-lighter px-3 py-2 fh" fluid>
    <v-row class="px-6 py-5 notification-header" justify="center" align="center" >
      <v-col class="pa-0 ma-0" cols="1">
        <v-btn icon color="#EA4965" @click="()=>($router.push({ name: 'message'}))">
          <img style="color: white; width: 28px; height: 23px; -webkit-transform: scaleX(-1); transform: scaleX(-1);" src="/img/icons/sidebar/right-arrow.svg"/>
        </v-btn>
      </v-col>
      <v-col class="pl-8 pa-0 ma-0" cols="2">
        <v-avatar size="48">
          <img :src="chatBot.thumbnail" >
        </v-avatar>
      </v-col>
      <v-col class="pa-0 ma-0" cols="9">
        <p class="text-center pa-0 ma-0 chatBot-name fill-height align-content-center justify-center">{{chatBot.name}}</p>
      </v-col>
    </v-row>
    <v-row id="notification-body" class="px-6 fh notification-body"  >
      <div class="d-flex flex-column-reverse fw " v-for="allMessages in getChatBotMessages()" :key="allMessages.id" >
        <v-card v-if="showMessageIfMessageBodyNotEquals(message.body)"
                v-for="message in allMessages.messages"
                :key="message.id"
                :class="message.messageClass"
                class="pa-4 my-2"
                outlined
                tile
        >
          <p class="notification-text" v-html="markdownToHtml(message.body)"></p>
          <div v-if="message.buttons && message.buttons.length > 0">
            <v-row class="d-flex justify-center">
            <div v-for="(button) in message.buttons" :key="button.label" class="mx-2">
              <generic-btn style="font-size: 15px !important; margin-top: 8px !important;; margin-bottom: 8px !important" :btn-props="{ rounded: true }" :on-click="() => functionCall(button.action)">
                {{button.label}}
              </generic-btn>
            </div>
            </v-row>
          </div>
        </v-card>
      </div>

    </v-row>
    <form  @submit.prevent="sendChatBotMessage()">
    <v-row id="input-text-chatbot" class="input-text-chatbot mt-11 pt-5 d-flex justify-end" >

      <v-col cols="12" class="pa-0 mt-6 input-message-chat"  color="white">
        <v-text-field v-model="inputTextArea" class="mx-5 white-input" placeholder="Digite aqui sua Mensagem" style="color: white !important;">
          <v-icon class="fa-3x" slot="prepend" color="white">far fa-comment-dots</v-icon>
          <v-icon class="fa-3x" slot="append" color="white" large @click="()=>(sendChatBotMessage())">fas fa-location-arrow</v-icon>
        </v-text-field>
      </v-col>
    </v-row>
    </form>
  </v-container>
</template>

<script>
import MessageService from "../../services/socialnetwork/MessageService";
import store from "../../store/store";
import {mapGetters, mapMutations} from "vuex";
import moment from "moment"
import ChatBotService from "../../services/euprotegido/ChatBotService";
import GenericBtn from "../../components/generic-btn";
import {marked} from 'marked';
import WebSocketService from "@/services/euprotegido/WebSocketService";
export default {
  name: "chat",
  components: {
    GenericBtn
  },
  data: () => ({
    chatBot : {},
    inputTextArea: ""
  }),
  created () {
    this._messageService = new MessageService();
    this._chatBotService = new ChatBotService();
    this._websocket = new WebSocketService();
    this._websocket.Connect();
    moment.locale('pt-br');
    //this.loadMessages();
  },
  mounted: function () {
    this.chatBot = this.$route.params.chatBot;
    if(!this.chatBot)
      this.chatBot = this.getChatBots()[0];
    this.container = document.getElementById ( "notification-body" )
    if(this.container)
      this.container.scrollTop = this.container.scrollHeight;
  },
  watch: {
    '$store.state.chatBotMessages'(newValue){
      this.this.setChatBotMessages(this.removeRepeatMessages(newValue));
    }
  },
  methods:{
    ...mapMutations(['loading', 'setChatBotMessages', 'setConversationScroll']),
    ...mapGetters(["getChatBotMessages", "getChatBots"]),
    showMessageIfMessageBodyNotEquals(messageBody){
      return messageBody !== '#teams'
    },
    sendChatBotMessage(){
      this._websocket = new WebSocketService();
      this._websocket.Connect();
      if(!this.inputTextArea)
        return;

      const channelAccessTime =  this.$auth.getSession() ? this.$auth.getSession().createDate : null;
      const msg = {
        entityId: this.chatBot.id,
        Content: {
          Text: this.inputTextArea
        },
        channelAccessTime
      };
      this.loading(true);
      this._chatBotService.SendMessageToChatBot(msg)
              .then((response) => {
                const chabotId = this.chatBot.id;
                let allMessages = this.getChatBotMessages().filter(function( m ) {
                  return m.chatbot == chabotId;
                });
                allMessages[0].messages.unshift({body: this.inputTextArea, type:'user', messageClass: 'user-message'} );
                this.setChatBotMessages(allMessages);
                setTimeout(function(){ if(this.setConversationScroll) this.setConversationScroll();}, 500);
              })
              .catch(error => {
                store.commit('showmsg', { text: "Erro ao enviar Mensagem", type: "error" });
              })
              .finally(() => {
                this.inputTextArea = "";
                this.loading(false);
              })
    },
    removeRepeatMessages(chatBotMessages){
      let messagesToRemove = []
      chatBotMessages[0].messages.forEach((message, index) => {
        if(index > 0){
          if(JSON.stringify(chatBotMessages[0].messages[index]) == JSON.stringify(chatBotMessages[0].messages[index - 1])){
            messagesToRemove.push(index - 1);
          }
        }
      })

      messagesToRemove.forEach(index => {
        chatBotMessages[0].messages.splice(index, 1);
      })
      return chatBotMessages;
    },
    functionCall(name){
      this[name]();
    },
    novaConversa(){
    },
    abrirAtendimento(){
      this.$router.push("/atendimentos/opcoes");
    },
    socilitarReembolso(){
      this.$router.push("atendimentoOperadora?type=reembolso&caseType=Reembolso");
    },
    consultarRede(){
      this.$router.push("atendimentoOperadora?type=rede_credenciada&caseType=Rede%20Credenciada");
    },
    duvidaCoparticipacao(){
      this.$router.push("atendimentoOperadora?type=coparticipacao&caseType=Coparticipação");
    },
    consultarCobertura(){
      this.$router.push("atendimentoOperadora?type=cobertura&caseType=Cobertura");
    },
    segundaViaCartao(){
      this.$router.push("atendimentoOperadora?type=segunda_via_carteirinha&caseType=Segunda Via Carteirinha");
    },
    abrirProtocoloCovid(){
      this.$router.push({name: 'miniHomeCovid'});
    },
    chatBotSim(){
      this.inputTextArea = "Sim";
      this.sendChatBotMessage();
    },
    chatBotNao(){
      this.inputTextArea = "Não";
      this.sendChatBotMessage();
    },
    chatBotTeams(){
      this.inputTextArea = "#teams";
      this.sendChatBotMessage();
    },
    markdownToHtml(message){
        return marked(message);
    },
  }
}
</script>


<style>
  .white-input input[type="text"] {
    color: white !important;
  }

  .white-input input[type="text"]::placeholder {
    color: rgba(211,211,211,0.5);
  }
</style>
<style scoped>

  .input-message-chat .v-icon{
    font-size: 28px !important;
    padding-bottom: 5px!important;
  }

  .notification-header{
    background-color: #532E88;
    position: fixed;
    z-index: 1;
    width: 100%;
  }

  .notification-body {
    max-height: calc(100vh - 180px) !important;
    overflow: visible !important;
    position: relative;
    overflow-y: scroll !important;
    top: 10%;
    margin-bottom: 10px;
  }

  .chatBot-name{
    font-family: "Open Sans";
    color: white;
    font-size: 18px;
  }

  .bot-message {
    background-color: #c9aad1;
    border-radius: 25px 25px 25px 10px !important;
    margin-right: 15px !important;
  }

  .user-message {
    background-color: white ;
    border-radius: 25px 25px 10px 25px !important;
    margin-left: 15px !important;
  }

  .notification-text {
    font-family: "Open Sans";
    line-height: 25px;
  }

  .message-time{
    font-family: "Open Sans";
    font-size: 14px;
    color: gray;
  }

  .message-inside-card {
    max-width: 97% !important;
    min-width: 97% !important;
    background-color: #532E88;
    border-radius: 25px 25px 25px 25px !important;
  }

  .message-inside-title{
    color: white;
    font-family: "Open Sans";
    font-size: 16px;
    font-weight: bold;
    line-height: 22px;
    margin-bottom: 5px;
  }

  .message-inside-text {
    color: white;
    font-family: "Open Sans";
    font-size: 14px;
    line-height: 19px;
    margin-bottom: 5px;
  }

  .message-inside-remember {
    color: lightgray;
    font-family: "Open Sans";
    font-size: 14px;
    line-height: 19px;
    margin-bottom: 5px;
  }

  .input-message-chat {
    background-color: #532E88;
    padding-bottom: 10px !important;
  }

</style>


